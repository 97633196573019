<template>
  <div class="legal-research-container">
    <button class="search-history-btn" @click="openSearchHistory">
      <span class="material-symbols-rounded search-history-icon">schedule</span>
      <span class="search-history-text">Search History</span>
    </button>
    <main class="main-content">
      <section class="search-section">
        <header class="search-header">
          <h2 class="search-title">Case Law Search</h2>
          <p class="search-description">
            Search for judgments, legal cases, or rulings across various
            jurisdictions.
          </p>
        </header>
        <div class="search-and-filter">
          <div class="search-form">
            <div class="search-input-wrapper">
              <span class="material-symbols-rounded search-icon">search</span>
              <input
                v-model="statement"
                type="text"
                class="search-input"
                placeholder="Can second wife claim husband's insurance money?"
                aria-label="Search query"
              />
            </div>
            <button @click="performSearch" :disabled="!isAllFieldsValid" class="btn" :class="{'search-submit-btn': !isAllFieldsValid, 'search-submit-btn-active': isAllFieldsValid }">
              <span class="material-symbols-rounded search-submit-icon"
                >send</span
              >
            </button>
          </div>
          <div class="filter-section">
              <div class="jurisdiction-filter">
              <div class="filter-header" @click="toggleJurisdiction">
                <span class="material-symbols-rounded filter-icon me-2"
                  >language</span
                >
                <span class="filter-title">Jurisdiction and Courts</span>
                <span class="material-symbols-rounded dropdown-icon"
                  >arrow_drop_down</span
                >
              </div>
              <div class="selected-jurisdictions">
                <span
                  v-for="(juri, index) in selectedJurisdictions"
                  :key="index"
                  class="jurisdiction-badge"
                >
                  {{ formatSelectedJurisdiction(juri.j) }} ({{ formatSelectedCourts(juri.c) }})
                  <span
                    class="material-symbols-rounded remove-icon"
                    @click="removeJurisdiction(index)"
                  >
                    close
                  </span>
                </span>
              </div>
            </div>
            <JurisdictionSelector
              v-if="showJurisdiction"
              @update-jurisdiction="handleUpdateJurisdiction"
              @toggle-jurisdiction="toggleJurisdiction"
              v-clickaway="toggleJurisdiction"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="advanced-filters">
            <button class="filter-btn pe-none">
              <span class="material-symbols-rounded filter-icon me-2"
                >filter_list</span
              >
              <span>Filters :</span>
            </button>
            <span class="filter-badge cursor-pointer" @click="toggleDateBox">
              Date Range
              <span class="material-symbols-rounded remove-icon"
                >keyboard_arrow_down</span
              >
            </span>

            <!-- <span class="filter-badge cursor-pointer" @click="toggleCitation">
              Citation Count
              <span class="material-symbols-rounded remove-icon"
                >keyboard_arrow_down</span
              >
            </span> -->
          </div>
          <div class="advanced-filters">
            <!-- <button class="clearall-button" @click="clearAll">Clear</button> -->
            <button class="clearall-button" @click="resetAll">Reset</button>
          </div>
        </div>
        <div v-if="showDateBox" class="position-relative" v-clickaway="toggleDateBox">
          <div class="search-card">
            <div>
              <h6 class="public_title">Publication Date</h6>
              <div class="date-inputs">
                <input
                  type="date"
                  placeholder="Start Date"
                  v-model="startDate"
                  @input="clearYears"
                  class="custom-date-input"
                />
                <input
                  type="date"
                  placeholder="End Date"
                  v-model="endDate"
                  @input="clearYears"
                  class="custom-date-input"
                />
              </div>
  
              <div class="radio-group">
                <div class="radio-option">
                  <input
                    type="radio"
                    id="last5"
                    value="5"
                    v-model="years"
                    @change="setYears"
                  />
                  <label for="last5">Last 5 Years</label>
                </div>
                <div class="radio-option">
                  <input
                    type="radio"
                    id="last10"
                    value="10"
                    v-model="years"
                    @change="setYears"
                  />
                  <label for="last10">Last 10 Years</label>
                </div>
                <div class="radio-option">
                  <input
                    type="radio"
                    id="last15"
                    value="15"
                    v-model="years"
                    @change="setYears"
                  />
                  <label for="last15">Last 15 Years</label>
                </div>
                <div class="radio-option">
                  <input
                    type="radio"
                    id="anyDate"
                    value="any"
                    v-model="years"
                    @change="clearDates"
                  />
                  <label for="anyDate">Any Date</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showCitation" class="position-relative">
          <div class="citation">
            <p>Citation Count</p>
            <input
              type="number"
              class="citation_input"
              placeholder="Enter Max Citation Count"
            />
          </div>
        </div>
      </section>

    </main>
  </div>
</template>

<script>
import { groupValidateInput } from "../../../store/utils";
import JurisdictionSelector from "./../JurisdictionSelector.vue";
import Auth from "@/store/Auth.js";
import CaseSearch from "@/store/casesearch.js";
export default {
  components: {
    JurisdictionSelector,
  },
  data() {
    return {
      showJurisdiction: false,
      selectedJurisdictions: [],
      showDateBox: false,
      showCitation: false,
      anyDate: false,
      startDate: "",
      endDate: "",
      years: "",
      from_date: "",
      to_date: "",
      recentSearches: [
        "Can second wife claim husband's insurance money?",
        "Driving in a drunken state is a violation of law.",
        "Alcohol, driver, accident.",
        "Domestic violence.",
      ],
      statement: "",
    };
  },
  watch: {
    startDate(newVal) {
      this.from_date = newVal;
    },
    endDate(newVal) {
      this.to_date = newVal;
    },
  },
  computed: {
    dateRange() {
      if (this.startDate && this.endDate) {
        return {
          from_date: this.startDate,
          to_date: this.endDate,
        };
      } else if (this.years) {
        this.setYears();
        return {
          from_date: this.startDate,
          to_date: this.endDate,
        };
      } else {
        return {
          from_date: null,
          to_date: null,
        };
      }
    },
    isAllFieldsValid() {
      const hasValidJurisdiction = () => {
        if (this.selectedJurisdictions && this.selectedJurisdictions.length) {
          return this.selectedJurisdictions.every((jurisdiction) => Object.hasOwn(jurisdiction, 'c') && jurisdiction.c.length);
        }
        return false;
      }
      return hasValidJurisdiction() && this.statement;
    }
  },
  methods: {
    openSearchHistory() {
      this.$router.push({ name: "CaseLawSearchHistory" });
    },
    toggleJurisdiction() {
      this.showJurisdiction = !this.showJurisdiction;
      this.countrySelector = true;
    },
    toggleDateBox() {
      this.showDateBox = !this.showDateBox;
    },
    setYears() {
      const currentYear = new Date().getFullYear();
      this.startDate = `${currentYear - this.years}-01-01`;
      this.endDate = `${currentYear}-12-31`;
    },
    clearYears() {
      this.years = "";
    },
    clearDates() {
      this.startDate = "";
      this.endDate = "";
    },
    toggleCitation() {
      this.showCitation = !this.showCitation;
    },
    handleUpdateJurisdiction(jurisdictions) {
      // console.log("Array of Jurisdictions",jurisdictions);
      // console.log("All Jurisdictions Lenght",jurisdictions.length);
      // console.log("Jurisditions 0 Court Lenght",jurisdictions[0].c.length);
      // console.log("Jurisditions 1 Court Lenght",jurisdictions[1].c.length);
    
      if (jurisdictions && jurisdictions[0] && jurisdictions[0].c && jurisdictions[0].c.length === 0) {
        this.selectedJurisdictions.pop();

        if (jurisdictions.length === 2){
          this.$toast.error("Please select states and it's court first before choosing another jurisdiction");
          this.toggleJurisdiction();
          this.selectedJurisdictions = []; 
        }
      }
      else if (jurisdictions && jurisdictions[1] && jurisdictions[1].c && jurisdictions[1].c.length === 0) {
        if(jurisdictions.length === 3){
          this.$toast.error("Please select states and it's court first before choosing another jurisdiction");
          this.toggleJurisdiction();
          this.selectedJurisdictions = []; 
        }
      } 
      else if (jurisdictions && jurisdictions[2] && jurisdictions[2].c && jurisdictions[2].c.length === 0) {
        console.log("Selected Jurisdictions [3] court 0",this.selectedJurisdictions)
      }
      else{
        this.selectedJurisdictions = jurisdictions; // Update with the selected data from child
        // console.log("Main SELECTED JURISDICTIONS",this.selectedJurisdictions)
      }
     
    },
    formatSelectedJurisdiction(jurisdiction) {
      const countryMapping = {
        Australia: 'AUS',
        USA: 'USA',
        Canada: 'CAN',
        'New Zealand': 'NZ',
        Singapore: 'SG',
        Malaysia: 'MY',
        'United Kingdom': 'UK',
      };
      return countryMapping[jurisdiction];
    },
    formatSelectedCourts(courts) {
      // Assuming `courts` is an array of court names
      if (courts.length > 2) {
        return `${courts[0]},...`;
      } else {
        return courts.join(", ");
      }
    },
    removeJurisdiction(index) {
      // Remove the jurisdiction by index
      this.selectedJurisdictions.splice(index, 1);
    },
    // clearAll() {},
    resetAll() {
      this.showJurisdiction = false;
      this.showCitation = false;
      this.showDateBox = false;
      this.clearYears();
      this.clearDates();
      this.selectedJurisdictions = [];
      this.statement = "";
    },
    toastError(flag) {
      this.$toast.error(
        {
          empty: "Please fill in all required fields",
          max: "Maximum word limit exceeded",
          courts: "Please select court(s) for each chosen jurisdiction",
        }[flag]
      );
    },
    performSearch() {
      const jurisdictions = this.selectedJurisdictions.map((item) => {
        return {
          jurisdiction: item.j,
          courts: item.c,
        };
      });
      const results = groupValidateInput([
        [this.statement, "string", ["max50"]],
        [jurisdictions, "courts"],
      ]);

      for (let i = 0; i < results.length; i++) {
        if (!results[i][0]) {
          this.toastError(results[i][1]);
          return;
        }
      }

      const locations = localStorage.getItem('locations') ? JSON.parse(localStorage.getItem('locations')) : [];
      const foundLocation = locations.find(x => x.title === jurisdictions[0]['jurisdiction']);

      const obj = {
        search_type: "and",
        statement: [results[0][1]],
        location_id: foundLocation.id,
        language: "en",
        from_date: this.startDate || "2024-05-05",
        to_date: this.endDate || "2024-09-05",
        jurisdictions: results[1][1],
      };

      Auth.getMe()
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          switch (res.data.data.nexlaw_credits) {
            case undefined:
            case "undefined":
            case null:
            case "null":
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
              break;
          }
        })
        .then(() => {
          return CaseSearch.caseSearch(obj);
        })
        .then((response) => {
          this.$toast.success(response.data.message);
          this.$router.push({
            name: "CaseLawSearchResult",
            params: { id: response.data.data.id },
          });
          // this.currentPage = 1;
          // this.emptyInputs();
          // this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.legal-research-container {
  background: #fafbfc;
}

.page-header {
  background: #fff;
  padding: 16px 20px;
}

.page-title {
  color: #383a3e;
  font: 600 24px Poppins, sans-serif;
}

.tabs-navigation {
  border-bottom: 1px solid #f2f3f3;
  background: #fff;
  padding-right: 12px;
}

.tabs-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tab-item {
  color: #86888d;
  font: 400 14px/24px Poppins, sans-serif;
  padding: 8px 16px;
  cursor: pointer;
}

.tab-item.active {
  border-bottom: 2px solid #0e4485;
  color: #0e4485;
}

.search-history-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: #0e4485;
  color: #fff;
  border-radius: 4px;
  font: 500 16px Poppins, sans-serif;
  margin: 16px 32px 0 auto;
  padding: 4px 16px;
  cursor: pointer;
}

.search-history-btn:hover {
  color: #d1d2d5;
}

.search-history-icon {
  width: 24px;
  height: 24px;
}

.main-content {
  margin-top: 16px;
  padding: 0 20px;
}

.search-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 40px;
}

.search-header {
  text-align: center;
  margin-bottom: 32px;
}

.search-title {
  color: #383a3e;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
}

.search-description {
  color: #86888d;
  font-size: 14px;
  line-height: 24px;
}

.search-form {
  display: flex;
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  overflow: hidden;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 20px;
}

.search-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.search-input {
  border: none;
  flex-grow: 1;
  font: 400 16px/28px Poppins, sans-serif;
  color: #383a3e;
  padding: 8px 0;
}

.search-input::placeholder {
  font-size: 16px !important;
}

a {
  text-decoration: none;
}

.search-submit-btn {
  display: flex;
  align-items: center;
  background: #0e4485;
  border: none;
  opacity: 0.6;
  padding: 8px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 0px 8px 8px 0px !important;
}

.search-submit-btn:hover {
  opacity: 1;
}

.search-submit-btn-active {
  display: flex;
  align-items: center;
  background: #0e4485;
  border: none;
  padding: 8px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 0px 8px 8px 0px !important;
}

.search-submit-icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.filter-section {
  position: relative;
  margin-top: 16px;
}

.jurisdiction-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.filter-header {
  padding: 5px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #86888d;
}

.filter-header:hover {
  background: #f2f3f3;
  border-radius: 4px;
}

.filter-icon,
.dropdown-icon {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
}

.selected-jurisdictions {
  display: flex;
  gap: 8px;
}

.jurisdiction-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f2f3f3;
  border: 1px solid #d1d2d5;
  border-radius: 1000px;
  padding: 0 8px;
  font-size: 12px;
  color: #86888d;
}

.remove-icon {
  font-size: 18px;
  cursor: pointer;
}

.jurisdiction-limit {
  font-size: 12px;
  color: #86888d;
  margin-top: 8px;
}

.advanced-filters {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  font: 500 14px Poppins, sans-serif;
  color: #86888d;
  cursor: pointer;
}

.filter-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  border: 1px solid #d1d2d5;
  border-radius: 1000px;
  padding: 0 8px;
  font-size: 14px;
  color: #86888d;
  position: relative;
}

@media (max-width: 991px) {
  .legal-research-container {
    padding-bottom: 100px;
  }

  .search-history-btn {
    margin-right: 10px;
  }

  .search-section {
    padding: 20px;
  }
}
</style>
<style scoped>
.search-card {
  /* width: 30%; */
  /* margin-top: -3%; */
  /* margin-left: 12%; */
  left: 9%;
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

.date-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.date-inputs input {
  width: 48%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-option input {
  margin-right: 8px;
}

.radio-option label {
  font-size: 14px;
  color: #666;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
  transform: scale(1.1);
  accent-color: var(--primary);
}

input[type="text"]::placeholder {
  color: #aaa;
  font-size: 12px;
}

/* Media query for responsive layout */
@media (max-width: 600px) {
  .search-card {
    width: 100%;
  }

  .date-inputs input {
    width: 100%;
    margin-bottom: 8px;
  }
}

.citation {
  /* margin-top: -3%; */
  /* margin-left: 22%; */
  /* width: 25%; */
  left: 21.5%;
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.citation_input {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.citation_input::placeholder {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
}

.clearall-button {
  border: none;
  border-radius: 20px !important;
  color: #86888d;
  font-size: 14px;
  cursor: pointer;
}
</style>
