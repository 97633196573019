<template>
  <div>
    <div id="Header">
      <h3>Legal Research</h3>
    </div>
    <!-- tabs -->
    <nav class="navbar navbar-expand-lg bg-white">
      <div class="navbar-nav">
        <a @click.prevent="activeTab = 'CaseLawSearch'"
          :class="['nav-link cursor-pointer', { active: activeTab === 'CaseLawSearch' }]">Case Law Search</a>
        <a @click.prevent="activeTab = 'LegislationSearch'"
          :class="['nav-link cursor-pointer', { active: activeTab === 'LegislationSearch' }]">Legislation Search</a>
      </div>
    </nav>
    <div class="scrollable-content">
      <CaseLawSearch v-if="activeTab === 'CaseLawSearch'"></CaseLawSearch>
      <LegislationSearch v-else></LegislationSearch>
    </div>
    
  </div>
</template>

<script>
import CaseLawSearch from './case_law_search/main.vue';
import LegislationSearch from './legislation_search/main.vue';
export default {
  components: {
    CaseLawSearch,
    LegislationSearch
  },
  data() {
    return {
      activeTab: 'CaseLawSearch'
    }
  }
}
</script>

<!-- Search bar -->
<style scoped>

/* .scrollable-content {
  max-height: 80vh;
  overflow-y: auto;
} */

#Search {
  height: 44px;
  border: 1px solid var(--light-grey);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

#Search span {
  margin: 12px;
  color: var(--dark-grey);
}

#Search input {
  width: calc(100% - 60px);
}

.form-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px;
  font: 500 16px Poppins, sans-serif;
}
</style>

<style scoped>
.navbar {
  background-color: white;
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  padding: 0px;
  margin-left: -20px;
  margin-right: -20px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 8px 20px;

  &.active {
    border-bottom: 2px solid var(--Primary-Blue, #0e4485);
    color: var(--Primary-Blue, #0e4485);
  }
}

h3,
p {
  margin-bottom: 0px;
}

#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  align-items: center;
  background-color: white;
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.add {
  background-color: var(--mid-grey);
  border-radius: 50px;
}

/* Client */
.client_title {
  color: var(--neutral-black) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.client_card {
  background: var(--interface-grey);
  width: 816px;
  height: 96px;
  border-radius: 8px;
  border: var(--light-grey);
  padding-top: 1rem;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.gw {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.radio {
  width: 20px;
  height: 20px;
}

.border-btm {
  border-bottom: 1px solid var(--mid-grey);
}

.btn {
  padding: 4px 16px;
  border-radius: 4px;
  height: 32px;
  justify-content: center;
}

.cancel-button,
.search-button {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.cancel-button,
.search-button {
  background-color: #f2f3f3;
  color: #d1d2d5;
  border: none;
}

.search-button {
  background: #0E44851A;
  color: #d1d2d5;
  border: none;
}

.arrow {
  width: 24px;
  height: 24px;
}

.border_error {
  outline: 1px solid var(--status-red);
  width: 824px;
  height: 140px;
  border-radius: 8px;
  padding: 4px;
}

.background_error {
  background-color: #FF4D4F1A;
}

.type_error {
  width: 420px;
  height: 33px;
  padding: 4px;
  border-radius: 8px;
  outline: 1px solid var(--status-red);
}

.sm {
  color: var(--status-red);
}

#Error {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon_info {
    vertical-align: middle;
    font-size: 18px;
    color: var(--neutral-black);
    font-variation-settings: 'FILL' 1;
  }
}

p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
</style>
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-black);
}

.delete-prompt .bg {
  color: var(--dark-grey) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>
<style scoped>
.custom-radio-wrapper {
  display: flex;
  align-items: center;
}

.custom-radio-input {
  position: absolute;
  opacity: 0;
}

.custom-radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
}

.custom-radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--dark-grey);
}

.custom-radio-input:checked+.custom-radio-label .custom-radio-circle {
  background-color: var(--light-blue);
  border-color: var(--primary);
}

.custom-radio-circle::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  transform: scale(0);
  transition: transform 0.3s;
}

.custom-radio-input:checked+.custom-radio-label .custom-radio-circle::after {
  transform: scale(1);
}

.custom-radio-text {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-grey);
}

.custom-radio-input:checked+.custom-radio-label .custom-radio-text {
  color: var(--primary);
}

.create_btn {
  border-radius: 4px;
  background-color: var(--primary);
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 12px;
}
</style>